'use client';

import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";
import {User} from "@/types/user";
import {Token} from "@/types/token";
import {ApiEndpoints} from "@/constants/api-endpoints";

export interface UserState {
    token: Token | null,
    user: User | null,
    setToken: (token: Token | null) => void;
    setUser: (user: User | null) => void;
    signOut: () => void;

    getUser(): Promise<User>;

    isAdmin: () => boolean;
}

const initialState: UserState = {
    token: null,
    user: null,
    setToken: () => {
    },
    setUser: () => {
    },
    signOut: () => {
    },
    getUser(): Promise<User> {
        throw new Error('Not implemented');
    },
    isAdmin: () => false,
}

const localStorageKey = 'user-storage';

export const useUserStore = create<UserState>()(devtools(
    persist(
        (set, get) => ({
            ...initialState,
            setToken: (token: Token | null) => set({token}),
            setUser: (user: User | null) => set({user}),
            signOut: () => set({token: null, user: null}),
            getUser: async () => {
                let user = get().user;
                if (user) {
                    return user;
                }
                user = await ApiEndpoints.getUser({});
                set({user});
                return user;
            },
            isAdmin: () => {
                //TODO :: retirer ça !!!!
                // return get().user?.account_id === 0 || (get().user?.account?.administeredAccountsIds.length ?? 0) > 0;
                return true;
            },
        }),
        {
            name: localStorageKey,
        }
    )
));
